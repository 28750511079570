import { Action, Dispatch } from "redux"
import axios from "../../axios/axios"
import { Auth } from "../../ts/Auth"
import { Skupina } from "../../ts/CSSkupiny"

export const ADD_SKUPINA =
  (skupina: Skupina, auth: Auth) =>
  async (dispatch: Dispatch<Action<"ADD_SKUPINA">>) => {
    const req = await axios.post("/cs-skupiny/add", {
      skupina,
      id: auth.user.id,
    })

    skupina.id = req.data.id

    dispatch({
      type: "ADD_SKUPINA",
      payload: skupina,
    })
  }

export const EDIT_SKUPINA =
  (skupina: Skupina, auth: Auth) =>
  async (dispatch: Dispatch<Action<"EDIT_SKUPINA">>) => {
    await axios.post("/cs-skupiny/edit", {
      skupina,
      id: auth.user.id,
    })

    dispatch({
      type: "EDIT_SKUPINA",
      payload: skupina,
    })
  }

export const DELETE_SKUPINA =
  (skupina: Skupina, auth: Auth) =>
  async (dispatch: Dispatch<Action<"DELETE_SKUPINA">>) => {
    await axios.post("/cs-skupiny/delete", {
      skupina,
      id: auth.user.id,
    })
    dispatch({
      type: "DELETE_SKUPINA",
      payload: skupina,
    })
  }

export const GET_SKUPINY =
  (auth: Auth) => async (dispatch: Dispatch<Action<"SET_SKUPINY">>) => {
    const req = await axios.get("/cs-skupiny", {
      params: {
        id: auth.user.id,
      },
    })

    if (req.data.length > 0)
      dispatch({
        type: "SET_SKUPINY",
        payload: req.data,
      })
  }
