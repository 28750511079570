import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import classNames from "classnames"
import { Row } from "../../../../ts/UI"
import Cenik from "../../Cenik/Cenik"
import "./CennikPopup.scss"
import { Item } from "../../../../ts/Cenik"
import { Polozka } from "../../../../ts/CP"

interface Props {
  active: boolean
}

interface CennikPopupReference {
  initialize(callback: (item: Polozka) => void): void
}

const CennikPopup = forwardRef<CennikPopupReference, Props>(
  ({ active }: Props, ref) => {
    const awaitResponse = useRef<((item: Polozka) => void) | null>(null)

    useImperativeHandle(
      ref,
      () => ({
        initialize(callback: (item: Polozka) => void) {
          awaitResponse.current = callback
        },
      }),
      [awaitResponse.current]
    )

    return (
      <div className={classNames("cennik-popup", { active })}>
        <div className="cennik-list-title">Kliknutím vyberiete položku</div>
        <Cenik
          rowClickHandler={(row: Row) => {
            if (awaitResponse) awaitResponse.current?.({
              id: row.item[0].value,
              kod: row.item[1].value,
              sirka: row.item[2].value,
              vyska: row.item[3].value,
              dokoncena_stena: row.item[4].value,
              popis: row.item[5].value,
              mj: row.item[6].value,
              cennikova_cena: row.item[7].value,
              dph: 23,
              mn: 1
            })
          }}
        />
      </div>
    )
  }
)

export default CennikPopup
